@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins-bold-webfont.woff2") format("woff2"),
    url("../fonts/poppins-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins-medium-webfont.woff2") format("woff2"),
    url("../fonts/poppins-medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins-regular-webfont.woff2") format("woff2"),
    url("../fonts/poppins-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
