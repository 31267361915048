.Hero {
  .subheadline {
    li:not(:nth-last-of-type(1)) {
      @apply mr-2 md:mr-3 lg:mr-5 xl:mr-6 flex items-center gap-x-2 md:gap-x-3 lg:gap-x-5 xl:gap-x-6;

      &::after {
        content: "";
        @apply block w-1 xl:w-2 h-1 xl:h-2 bg-primary rounded-md;
      }
    }
  }

  .wysiwyg {
    strong {
      hyphens: manual;
      @media (min-width: theme("screens.md")) {
        white-space: pre;
      }
    }
  }
}
