*:focus {
  @apply outline-0;
}

.hover-underline {
  @apply relative;

  &::after {
    content: "";

    @apply absolute left-0 right-0 bg-current h-px bottom-1 origin-right scale-x-0 transition-transform duration-300;
  }

  &:hover,
  &:focus {
    &::after {
      @apply scale-x-100 origin-left;
    }
  }
}

.hover-logo {
  strong {
    @apply transition-transform duration-300;
  }
  &:hover,
  &:focus {
    strong {
      @apply -translate-x-1;
    }
  }
}

.hover-move-icon {
  svg {
    @apply transition-transform duration-300;
  }
  &:hover,
  &:focus {
    svg {
      @apply translate-x-1;
    }
  }
}

.hover-circle-bg {
  @apply relative isolate;

  &::after {
    content: "";

    --offset: 0.5rem;

    left: calc(-1 * var(--offset));
    top: calc(-1 * var(--offset));
    width: calc(100% + 2 * var(--offset));
    height: calc(100% + 2 * var(--offset));

    @apply absolute bg-white border-primary border-2 rounded-full -z-10 origin-center scale-0 opacity-0 duration-300;

    transition-property: transform, opacity;
  }

  &:hover,
  &:focus {
    &::after {
      @apply scale-100 opacity-100;
    }
  }
}
