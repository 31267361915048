.Accordion {
  .accordion-item {
    &:hover,
    &:focus {
      .accordion-headline {
        @apply translate-x-2;
      }
    }
    .accordion-icon {
      @apply transition-transform duration-500;
    }

    .accordion-headline {
      @apply transition-all duration-500;

      transition-property: transform, color;
    }

    &.open {
      .accordion-icon {
        @apply rotate-45;
      }

      .accordion-content {
        @apply border-primary/50;
      }

      .accordion-headline {
        @apply text-primary;
      }
    }
  }
}
