html {
  font-size: 16px;

  @apply scroll-smooth;
}

body {
  @apply font-poppins text-16 md:text-20 text-dark font-medium bg-white overflow-x-hidden;
}

a:focus,
button:focus {
  @apply outline-primary;
}
