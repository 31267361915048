@tailwind base;
@tailwind components;
@tailwind utilities;

@import "import-fonts";
@import "base";
@import "headline";
@import "swiper";
@import "wysiwyg";
@import "hover";
@import "loading-animation";
@import "components";
