.Header {
  @apply transition-colors duration-500;

  &.scroll {
    @apply bg-white;

    & > .container{
      @apply py-3 lg:py-5
    }
  }

  nav {
    height: calc(100vh - 100%);
    @apply translate-x-full hidden lg:translate-x-0 lg:flex lg:h-auto;

    .nav-item {
      @apply opacity-0 translate-y-5 lg:opacity-100 lg:translate-y-0;
    }

    &:not(:hover, :focus-within) {
      a[aria-current="page"] {
        @apply text-primary;
      }
    }
  }
}
