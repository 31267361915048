.ProjectTeaser {
  .tag-list {
    li:not(:nth-last-of-type(1)) {
      @apply mr-2 md:mr-3 flex items-center gap-x-2 md:gap-x-3;

      &::after {
        content: "";
        @apply block w-1 xl:w-2 h-1 xl:h-2 bg-current rounded-md;
      }
    }
  }
  .project {
    height: 70vh;

    &::after {
      content: "";

      @apply absolute left-0 top-0 w-full h-full -z-10 opacity-70 lg:opacity-100;

      background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    }
  }
}
