.Contact {
  .form {
    label {
      @apply transition-opacity;
    }
    label:has(+ :is(input, textarea):placeholder-shown) {
      @apply opacity-100;
    }
  }
}
