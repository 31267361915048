[data-loading-animation]:not([data-loading-animation-stagger]) {
  @apply translate-y-10 opacity-0;
}

[data-loading-animation-stagger] {
  & > * {
    @apply translate-y-10 opacity-0;
  }

  & > span {
    @apply inline-block;
  }
}
