.ScrollIntro {
  .word-container {
    overflow: hidden;
    display: inline-block;
    line-height: 1.1;
  }

  .word-container > span {
    display: inline-block;
  }
}
