.wysiwyg {
  strong {
    @apply text-primary;
  }
}

.wysiwyg-text-only{
  a{
    @apply text-primary underline underline-offset-2 hover:text-dark transition-colors duration-300 ease-in-out
  }
}