.GdprConsentModal {
  @apply fixed top-0 left-0 right-0 bottom-0 bg-dark/80 hidden isolate items-start justify-center p-10 xl:py-32;

  z-index: 999;

  &.show {
    @apply flex;
  }

  .consent-wrapper {
    @apply bg-white rounded-none p-6 md:p-12 z-10 h-full overflow-y-scroll;

    @media screen and (min-width: 480px) {
      max-width: 440px;
    }

    @media screen and (min-width: 768px) {
      max-width: 500px;
    }

    @media screen and (min-width: 1024px) {
      max-width: 620px;
    }

    @media screen and (min-width: 1280px) {
      max-width: 680px;
    }
  }
}

.GdprConsentModal,
.GdprConsentBrick {
  .script-list {
    @apply flex flex-col mt-8 border-b border-primary;

    &-item {
      @apply block py-2 border-t border-primary;

      &.is-open {
        svg {
          @apply rotate-180;
        }

        .script-item-info {
          @apply block;
        }
      }
    }
  }

  .script-item {
    &-btn {
      @apply flex items-center justify-between w-full px-4;

      &:focus span,
      &:active span {
        @apply text-primary;
      }

      span {
        @apply text-dark font-bold text-20 md:text-25;
      }
    }

    &-info {
      @apply bg-white p-4 hidden text-16 text-dark/80;
    }
  }

  .script-actions {
    @apply flex flex-col mt-6 lg:flex-row lg:justify-end;

    &-btn {
      @apply text-white bg-black hover:bg-primary transition-colors px-3 py-5 my-2 text-center select-none lg:ml-6;
    }
  }

  .GdprSwitch {
    @apply relative block cursor-pointer;

    input {
      @apply appearance-none block w-16 h-8 text-16 md:text-20 bg-dark text-white align-middle rounded-full;

      &:disabled {
        @apply cursor-not-allowed opacity-50;
      }

      &:focus {
        box-shadow: none !important;
      }

      &::before {
        content: "";

        @apply absolute top-0.5 left-0.5 bottom-0.5 right-1/2 bg-white z-10 origin-right rounded-full;

        animation: switch-off-transform 0.2s ease-out forwards;
      }

      &:checked {
        @apply text-white bg-primary;

        &::before {
          @apply origin-left;

          animation: switch-on-transform 0.2s ease-out forwards;
        }
      }

      &:focus {
        box-shadow: inset 0 0 0 2px theme("colors.dark");
      }
    }
  }
}

.gdpr-element {
  .gdpr-wrap,
  .gdpr-text {
    @apply hidden;
  }

  &.gdpr-loaded {
    .gdpr-wrap,
    .gdpr-text {
      @apply block;
    }

    .gdpr-loading {
      @apply hidden;
    }
  }
}

@keyframes switch-on-transform {
  0% {
    transform: translate(0) scaleX(1);
  }
  25% {
    transform: translate(0) scaleX(1.33);
  }
  100% {
    transform: translate(100%) scaleX(1);
  }
}

@keyframes switch-off-transform {
  0% {
    transform: translate(100%) scaleX(1);
  }
  25% {
    transform: translate(100%) scaleX(1.33);
  }
  100% {
    transform: translate(0) scaleX(1);
  }
}

body.modal-active {
  @apply relative h-screen overflow-hidden;
}

.AB-GdprConsent {
  @apply bg-white p-8;
  margin-top: -1px;
}
